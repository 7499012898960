<template>


  <div 
    v-if="category"
    :class="{'cti': true, 'disabled': !props.canVote || props.isFinished, 'voted': category.addon?.is_voted}"
  >
    <div class="br">{{ category.sub }}</div>
    <div class="nam">{{ category.name }}</div>
    <div class="ds">{{ category.description }}</div>



    <div class="voted-name" v-if="votedBrand || votedProduct">
      Your vote:
      <span class="nm">
        <template v-if="votedBrand">
          {{votedBrand?.name}}
        </template>
        <template v-else-if="votedProduct">
          {{votedProduct?.item_brand?.name}} - {{votedProduct?.name}}
        </template>
      </span>
    </div>

    <NuxtLink
      v-if="!useAuth().isAuth()"
      to="/auth/signin"
      class="bt"
    >
      Vote now
    </NuxtLink>

    <div
      v-else-if="!props.canVote"
      :class="{'bt': true, 'voted': category.addon?.is_voted}"
      @click="handleErrorVoting('Please, follow rules to vote')"
      :data-category-id="category.id"
    >
      <div class="btn-text">{{ category.addon?.is_voted ? 'Revote' : 'Vote now' }}</div>
    </div>

    <div
      v-else
      :class="{'bt': true, 'voted': category.addon?.is_voted}"
      @click="showed = true"
      :data-category-id="category.id"
    >
      <div class="btn-text">{{ category.addon?.is_voted ? 'Revote' : 'Vote now' }}</div>
    </div>

  </div>



  <GeneralSelectBoxModal
    v-if="showed"
    :cat="category.category_item"
    :input-placeholder="'Search...'"
    :title-modal="$t(`create_diary_add_brand_${category.category_item}_placeholder`)"
    :showed="showed"
    :faza="faza"
    :enable-suggest="false"
    :enable-custom="false"
    @choose="chooseItem"
    @prechoose="prechooseItem"
    @close="showed = false"
  />


  
</template>


<script setup lang="ts">

import type { Brand } from '@/types/brand'
import type { Product } from '@/types/product'
import type { Award, AwardCategory } from '@/types/award'
import { AwardCategoryType } from '@/types/award'

const {$api, $popup} = useNuxtApp()


interface Props {
  cupId: number
  category: AwardCategory
  isFinished: boolean
  canVote?: boolean
  votedBrand?: Brand
  votedProduct?: Product
}

const props = defineProps<Props>()
const isLoading = ref<boolean>(false)
const showed = ref<boolean>(false)

const chooseItem = (data) => {
  // console.log('props.category', props.category)
  // console.log('props.cupId', props.cupId)
  // console.log('data', data)
  if(props.category.type === AwardCategoryType.Brand && data.item_brand.id){
    // vote(data.item_brand.id)
    vote({
      brand_id: data.item_brand.id,
    })
    showed.value = false
  }else if(props.category.type === AwardCategoryType.Product && data.id){
    vote({
      product_id: data.id,
    })
    showed.value = false
  }
  
}

const prechooseItem = (data: any) => {
  // console.log(data)
}

const handleErrorVoting = (message: string) => {
  $popup.error(message)
}

const vote = (data: any) => {

  isLoading.value = true
  $api.postAwardVote(props.cupId, props.category.id, data)
    .then(response => {
      isLoading.value = false
      $popup.success('Voted');
      props.category.addon = {
        is_voted: true,
      }
    })
    .catch(error => {
      isLoading.value = false
      $popup.error('error');
    });
}


</script>
 


<style scoped>
 
 
.cti{
  border-radius: 10px;
  background-color: var(--un-background-color-gray);
  width: calc(100% / 4 - 2rem);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}
.cti .br{
    color: #a1a1a1;
    margin-bottom: 5px;
}
.cti .nam{
  font-weight: bold;
  min-height: 60px;
  font-size: 1.1rem;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.cti .ds{
  margin-top: 1rem;
  display: none;
}

.cti .bt{
  border-radius: 5px;
  padding: 10px;
  color: var(--un-text-color);
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: var(--un-firm);
  width: 100%;
  margin-top: auto;
  color: white;  
}
.cti.disabled{
  
}
.cti .bt:hover{
  background-color: var(--un-firm);
  color: white;
  opacity: 0.9;
}
.cti .bt .btn-text{
}
.cti .bt .cnt{
    margin-left: auto;
    display: none;
}
.cti .bt.voted{
  background-color: var(--un-background-color-gray-dark);
  color: var(--un-text-color);
}
.cti .bt.disabled{
  opacity: 0.4;
  pointer-events: none;
}

.voted-name{
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: var(--un-text-color);
}

.voted-name .nm{
  font-weight: bold;
}

@container pc (max-width: 600px) {
 
  .cti{
    width: 100%;
  }
  .cti .br{}
  .cti .nam{
    min-height: auto;
    margin-bottom: 1rem;
  }
  .cti .ds{}
  .cti .bt{}
  .cti .bt .btn{}
  .cti .bt .cnt{}
}

</style>