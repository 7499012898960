<template>
  <div class="aw" v-if="awardData">

    <AwardHero2025 
      v-if="awardData.year === 2025"
    />
    
    <AwardHero 
      v-else
      :caption="awardData.caption"
      :year="awardData.year"
    />

    <AwardAbout 
      :description="awardData.description"
    />

    <AwardRules 
      :rules="awardData.rules"
    />
 
    <!-- && (awardData.is_finished || useAuth().getAccess('view_extra')) -->

    <AwardVotes 
      v-if="awardData.items_vote?.length"
      :show-winners="awardData.is_finished"
      :cup-id="awardData.id"
      :categories="awardData.items_category"
      :votes="awardData.items_vote"
    />


    <AwardCategories 
      v-if="awardData.can_vote"
      :cup-id="awardData.id"
      :categories="awardData.items_category"
      :is-finished="awardData.is_finished"
      :can-vote="awardData?.addon?.can_vote"
      :user-votes="awardData?.addon?.votes"
    />


    <div class="comments">

      <CommentWidget v-if="awardData && awardData.comments_id"
        :title="$t('journal_view_post_comments')"
        :type="awardData.comments_type"
        :content="awardData.comments_id"
      />

    </div>

  </div>
</template>


<script setup lang="ts">

import type { Award } from '@/types/award'

const route = useRoute();
const { $api} = useNuxtApp()
const year = ref<string>(route.params['year'])

const loadData = async function(y: string) {   
  return await $api.getAwardSection(y);
}

const { pending: isLoading,  data: awardData } = await useLazyAsyncData('awardData', async () => await loadData(year.value) as Award)



</script>

<style scoped>


.aw{

}

.aw:deep(.wd){
    max-width: 1020px;
    margin: 4rem auto;
    padding: 0rem 1rem;
}
.aw .tp{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.aw .tp .lg{
    max-width: 200px;
    margin-right: auto;
}
.aw .tp .mn{}
.aw .tp .mn .mni{
    text-decoration: underline;
    margin: 0 8px;
}
.aw .tp .mn .mnib{
    background-color: #5aa62a;
    color: white;
    border-radius: 5px;
    padding: 7px 20px;
    margin-left: 10px;
}

.aw:deep(h2){
  font-size: 1.9rem;
  margin-bottom: 0.6rem;
  text-align: center;
  font-weight: 500;
}

.aw:deep(.desc){
  text-align: center;
  font-weight: 500;
}

.comments{
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
}

@container pc (max-width: 600px) {

  .aw:deep(.wd){
    max-width: 1020px;
    margin: 2rem auto;
    padding: 0rem 1rem;
  }
}

</style>