<template>


  <!-- CATEGORY -->

  <a name="categories"></a>

  <div class="cg wd">

    <h2>Nominations</h2>

    <div class="ds desc">
      Please vote only one time in each nomination.
    </div>

    <UserNotVerificated 
      v-if="useAuth().isAuth() && !useAuth().isVerifiedEmail()"
      :text="'Please verify your email to vote.'"
    />

    <div class="ct">
      <AwardCategory 
        v-for="category in props.categories" :key="category.id"
        :category="category"
        :cup-id="props.cupId"
        :is-finished="props.isFinished"
        :can-vote="props.canVote"
        :voted-brand="getVotedBrand(category.id)"
        :voted-product="getVotedProduct(category.id)"
        />
    </div>

  </div>

  
</template>


<script setup lang="ts">

import type { Brand } from '@/types/brand'
import type { Product } from '@/types/product'
import type { AwardCategory, AwardUserVote } from '@/types/award'

interface Props {
  cupId: number
  categories: AwardCategory[]
  isFinished: boolean
  canVote?: boolean
  userVotes?: AwardUserVote[]
}

const props = defineProps<Props>()


const getVotedBrand = (categoryId: number): Brand => {
  const userVote = props.userVotes?.find(vote => vote.category_id === categoryId)
  return userVote?.item_brand ?? null;
}

const getVotedProduct = (categoryId: number): Product => {
  const userVote = props.userVotes?.find(vote => vote.category_id === categoryId)
  return userVote?.item_product ?? null;
}

</script>
 


<style scoped>
 

.cg{
}
.cg .ds{
    margin-bottom: 2rem;
}
.cg .ct{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
 

@container pc (max-width: 600px) {

  .cg{}
  .cg .ds{}
  .cg .nam{

  }
  .cg .ct{
    
    overflow-x: auto;
        width: 100%;
        gap: 1rem;
  }
  .cg .ct::-webkit-scrollbar {
    display: none;
  } 
}

</style>